<template>
  <div class="main-box">
    <el-card class="box-card" shadow="never">
      <el-row style="padding-bottom: 10px;line-height: 40px">
        <el-col :span="24">
          <el-input ref="assetSn" v-model="formData.assetSn" placeholder="请输入或扫资产码" @keyup.enter.native="search" style="width: 250px"></el-input>
          <el-button type="primary" style="margin-left: 10px" :loading="loading" @click="search">查询</el-button>
          <el-button type="primary" @click="submit" :loading="commitLoading">打印仓库和不良标签</el-button>
          <MessageComponent ref="searchMessage"/>
        </el-col>
      </el-row>
      <MaterialInfo :busItem="busItem" :lendOrder="lendOrder" :lend-order-item="lendOrderItem" station="pack"/>
    </el-card>
  </div>
</template>

<script>
import MaterialInfo from "@/views/material/MaterialInfo";
import MessageComponent from "@/components/MessageComponent";

export default {
  name: "LabelPrint",
  components: {MaterialInfo, MessageComponent},
  data() {
    return {
      formData: {
        assetSn: ''
      },
      busItem: {},
      lendOrder: {},
      lendOrderItem: {},
      loading: false,
      commitLoading: false
    }
  },
  methods: {
    clearData() {
      this.formData.assetSn = ''
      this.busItem = {}
      this.lendOrder = {}
      this.lendOrderItem = {}
    },
    async search() {
      this.$refs.searchMessage.clear();
      if (!this.formData.assetSn) {
        this.$refs.searchMessage.showError('请输入或扫资产码');
        return;
      }

      this.loading = true;
      const {data: res} = await this.$axios.get('/busItem/queryInfoForDetail', {
        params: {assetSn: this.formData.assetSn}
      });
      this.loading = false;

      if (res.code !== 0) {
        this.$refs.searchMessage.showError(res.message);
        return
      }

      if (!res.data.busItem) {
        this.busItem = {};
        this.lendOrder = {};
        this.lendOrderItem = {};
        return this.$refs.searchMessage.showError('资产码不正确');
      }

      this.busItem = res.data.busItem || {};
      this.lendOrder = res.data.lendOrder || {};
      this.lendOrderItem = res.data.lendOrderItem || {};

      if (this.busItem.testResult == '1') {
        return this.$refs.searchMessage.showError('该物品检验结果为合格');
      }
    },
    async submit() {
      if (!this.formData.assetSn) {
        return this.$refs.searchMessage.showError('请输入或扫资产码');
      }
      if (!this.busItem.id) {
        return this.$refs.searchMessage.showError('请先查询资产信息');
      }
      if (this.$socket.notOk()) {
        return this.$refs.searchMessage.showError('未成功连接打印机小程序');
      }

      this.submitLoading = true;
      const {data: res} = await this.$axios.post('/busItem/printStoreAndTrouble', this.formData);
      this.submitLoading = false;
      if (res.code !== 0) {
        return this.$refs.searchMessage.showError(res.message);
      }
      if (res.data.testResult == '1') {
        return this.$refs.searchMessage.showError('该物品检验结果为合格');
      }

      let store = res.data.store;
      if (store) {
        this.$socket.send({
          "requestId": Math.round(Math.random() * 100000000),
          "printType": "store",
          "printer": process.env.VUE_APP_TROUBLE_CODE_PRINTER_NAME,
          "printVO": store
        })
      }
      let trouble = res.data.trouble;
      if (trouble) {
        let obj = {
          "requestId": Math.round(Math.random() * 100000000),
          "printType": "trouble",
          "printer": process.env.VUE_APP_TROUBLE_CODE_PRINTER_NAME,
          "printVO": trouble
        };
        this.$socket.send(obj);
        //不良标签打印2个完全一样的
        this.$socket.send(obj)
      }
      if (store || trouble) {
        this.$refs.searchMessage.showSuccess('打印指令已发送');
      }

      //清掉之前的资产码
      this.clearData();
      this.$refs.assetSn.focus();
    },
  }
}
</script>

<style scoped>

</style>